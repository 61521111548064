/* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import LoadingService from "../services/loading.service";
import { ISearchResults, SearchService } from "../api-generated";
import { BBJSearchService } from "@bbj/components";

@Injectable({
  providedIn: "root"
})
export default class SearchResolver {
  constructor(
    public router: Router,
    private loadingService: LoadingService,
    private searchService: SearchService,
    private bbjSearchService: BBJSearchService,
  ) {}

  resolve(): Observable<ISearchResults[]> {
    this.loadingService.start();
    const storedResults = this.bbjSearchService.checkResults();
    if(storedResults && storedResults.length > 0)
    {
      return of(storedResults);
    }
    return this.searchService.searchKeywords({term:this.bbjSearchService.searchTerm}).pipe(
      map((items) => {
        this.bbjSearchService.saveResults(items);
        this.loadingService.stop();
        return items;
      }),
      catchError((error: HttpErrorResponse) => {
        // console.log(error);
        this.router.navigate(["error", error.status ? error.status : 500]);
        return of(null);
      }),
    );
  }

}
